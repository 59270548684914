export const supportedTokensList = [
  {
    SYMBOL: "USDC",
    ADDRESS: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
  },

  {
    SYMBOL: "ARB",
    ADDRESS: "0x912CE59144191C1204E64559FE8253a0e49E6548",
  },
  { SYMBOL: "SUSHI", ADDRESS: "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A" },
  { SYMBOL: "WETH", ADDRESS: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1" },
  { SYMBOL: "DOHYO", ADDRESS: "0x2565956d4316Fd8F2A625c63B7549ab83834A73E" },
];
